<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Relatório de conversas</h1>
    <form class="mb-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3">
          <label for="busca" class="block text-sm font-medium">Buscar</label>
          <input v-model="busca" type="text" id="busca" placeholder="Buscar (nome, telefone, contrato, cpf)"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <label for="de" class="block text-sm font-medium">A partir de</label>
          <input v-model="de" type="datetime-local" name="de" id="de"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-2">
          <label for="ate" class="block text-sm font-medium">Até</label>
          <input v-model="ate" type="datetime-local" name="ate" id="ate"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2" v-if="$store.state.user.admin">
          <label for="dataDisparo" class="block text-sm font-medium text-gray-700">
            Centro de custo</label>
          <v-select v-model="centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <label for="operador" class="block text-sm font-medium text-gray-700">Operador</label>
          <v-select v-model="operador" :options="operadores" label="nome" :reduce="(p) => p._id" name="operador" id="operador" class="mt-1"></v-select>
        </div>
        <div class="col-span-12 md:col-span-3">
          <label for="desativo" class="block text-sm font-medium mb-3">
            <input type="checkbox" v-model="desativo" class="rounded-sm" id="desativo" />
            <span class="ml-2"> Filtrar conversas desativadas </span>
          </label>
          <label for="respondido" class="block text-sm font-medium mt-2 mb-3">
            <input type="checkbox" v-model="respondido" class="rounded-sm" id="respondido" />
            <span class="ml-2"> Filtrar conversas respondidas </span>
          </label>
        </div>
        <div class="col-span-12 md:col-span-4">
          <div class="w-full">
            <label for="respondido" class="block text-sm font-medium mb-1">
              Filtrar último a responder:
            </label>
            <label for="ultimoAResponder-Ambos" class="inline-flex items-center">
              <input type="radio" class="form-radio" for="ultimoAResponder-Ambos" name="ultimoAResponder"
                v-model="ultimoAResponder" value="Ambos">
              <span class="ml-2 text-xs md:text-sm">Ambos</span>
            </label>
            <label for="ultimoAResponder-Operador" class="inline-flex items-center ml-2">
              <input type="radio" class="form-radio" for="ultimoAResponder-Operador" name="ultimoAResponder"
                v-model="ultimoAResponder" value="Operador">
              <span class="ml-2 text-xs md:text-sm">Operador</span>
            </label>
            <label for="ultimoAResponder-Cliente" class="inline-flex items-center ml-2">
              <input type="radio" class="form-radio" for="ultimoAResponder-Cliente" name="ultimoAResponder"
                v-model="ultimoAResponder" value="Cliente">
              <span class="ml-2 text-xs md:text-sm">Cliente</span>
            </label>
          </div>
        </div>
        <div class="col-span-12 md:col-span-4">
          <div class="w-full">
            <label for="respondido" class="block text-sm font-medium mb-1">
              Filtrar data:
            </label>
            <label for="dataMensagem-Ambos" class="inline-flex items-center">
              <input type="radio" class="form-radio" for="dataMensagem-ultimo" name="dataMensagem"
                v-model="dataMensagem" value="ultimo">
              <span class="ml-2 text-xs md:text-sm">Última mensagem</span>
            </label>
            <label for="dataMensagem-Operador" class="inline-flex items-center ml-2">
              <input type="radio" class="form-radio" for="dataMensagem-primeira" name="dataMensagem"
                v-model="dataMensagem" value="primeira">
              <span class="ml-2 text-xs md:text-sm">Primeira Mensagem</span>
            </label>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <label class="block text-sm font-medium text-gray-700">Buscar conversas</label>
          <button @click="start" type="button"
            class="mt-1 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Buscar conversas</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <label class="block text-sm font-medium text-gray-700">Arquivar multiplas</label>
          <button @click="$modal.show('arquivarMultiplas')" type="button"
            class="mt-1 bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Arquivar conversas</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3">
          <label for="email" class="block text-sm font-medium">Email download relatório</label>
          <input v-model="email" type="email" name="email" id="email"
            class="mt-1 h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <label class="block text-sm font-medium text-gray-700">Download relatório</label>
          <button @click="downloadRelatorio" target="_blank" type="button" role="button"
            class="mt-1 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Download conversas</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2 mt-8 ml-2">
          <p v-if="isLoadingBusca" class="text-base text-zinc-600"><b>Progresso busca: </b><span class="text-green-600">{{ progressoBusca > 1 ? progressoBusca : 'Buscando conversas...'  }}</span> <span v-if="progressoBusca > 1">/ 100%</span></p>
          <p v-if="isLoading" class="text-base text-zinc-600"><b>Progresso download: </b><span class="text-green-600">{{ progresso > 1 ? progresso : 'Buscando conversas...' }}</span> <span v-if="progresso > 1">/ 100%</span></p>
        </div>
      </div>
    </form>
    <div class="grid md:grid-cols-3 gap-3 my-5">
      <label class="text-xl">Total conversas: <b class="text-green-500">{{ totalEnvio }}</b></label>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div v-for="(item, idx) in tags" :key="idx" class="col-span-12 md:col-span-4 2xl:col-span-3">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg px-4 py-5">
          <label class="block font-bold text-lg text-gray-800 mb-1">{{ item.tag }}</label>
          <label class="block text-gray-700 text-base font-semibold">Total conversas: <b class="text-green-500">{{
            item.total }}</b> </label>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-3">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ativa
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Contato
                  </th>

                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Operador
                  </th>

                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Centro de Custo
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tags
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Início Atendimento
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tempo Atendimento
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Msgs Enviadas
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Msgs Recebidas
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    {{ item.ativa ? 'Sim' : 'Não' }}
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    {{ item.contato ? item.contato : '' }}
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    {{ item.operador ? item.operador : '' }}
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    {{ item.centrocusto ? item.centrocusto : '' }}
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    <span class="block" v-for="(tag, idx) in item.tags" :key="idx">{{ tag && tag.nome ? tag.nome : ''
                    }}</span>
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    <span v-if="item.dataMensagem">
                      {{ item.dataMensagem | moment("DD/MM/YYYY HH:mm") }}
                    </span>
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    {{ returnTempo(item.tempo ? item.tempo : 0) }}
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    {{ item.totalmsgEnviadas ? item.totalmsgEnviadas : 0 }}
                  </td>
                  <td class="text-xs 2xl:text-sm px-2 py-3">
                    {{ item.totalmsgRecebidas ? item.totalmsgRecebidas : 0 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    <modal name="arquivarMultiplas" height="auto">
      <div class="px-2 py-3">
        <h1 class="text-lg font-bold mb-2">Arquivar contatos:</h1>

        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-12 md:col-span-12">
            <label for="centrocusto" class="block text-sm font-medium">Centro de Custo</label>
            <select v-model="form.centrocusto" required name="centrocusto" id="centrocusto"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              <option :value="null"> Verificar em todos </option>
              <option v-for="c in centroscusto" :key="c._id" :value="c._id">
                {{ c.nome }}
              </option>
            </select>
          </div>
          <div class="col-span-12 md:col-span-6">
            <input type="number" v-model="form.numero"
              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Número" />
          </div>
          <div class="col-span-12 md:col-span-6">
            <button @click="addNumeros" type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
              Adicionar
            </button>
          </div>
        </div>
        <div v-if="numeros.length" class="bg-gray-200 rounded px-2 py-2 mt-3">
          <div v-for="(n, idx) in numeros" :key="idx" class="flex flex-row py-2">
            <label class="py-1 text-sm font-semibold">{{ n }}</label>
            <button @click="numeros.splice(idx, 1)" type="button"
              class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm focus:ring-opacity-50 text-white w-20 ml-2 py-1 px-1 rounded-md text-xs shadow-sm hover:shadow-md font-semibold text-center">
              Remover
            </button>
          </div>
        </div>
        <button v-if="numeros.length" @click="arquivarMultiplas" type="button"
          class="inline-flex justify-center mt-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
          Arquivar números
        </button>
        <button v-if="form.centrocusto && !numeros.length" @click="desativarTodasCentroCusto" type="button"
          class="inline-flex justify-center mt-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
          Desativar todas conversas do centro de custo
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      isLoading: false,
      isLoadingBusca: false,
      progresso: '',
      progressoBusca: '',
      breadcrumb: [
        { url: "/relatorios/relatorio-chats", value: "Relatório de conversas" },
      ],
      moment: moment,
      tags: [],
      form: {
        numero: "",
        centrocusto: null
      },
      email: '',
      numeros: [],
      centroscusto: [],
      centrocusto: null,
      ultimoAResponder: "Ambos",
      dataMensagem: "ultimo",
      operadores: [],
      operador: null,
      totalEnvio: 0,
      respondido: false,
      desativo: false,
      busca: '',
      list: [],
      total: 0,
      page: 1,
      perPage: 20,
      de: moment().startOf("d").format("YYYY-MM-DDTHH:mm"),
      ate: moment().endOf("d").format("YYYY-MM-DDTHH:mm"),
    };
  },
  methods: {
    async start() {
      this.progressoBusca = 1;
      this.isLoadingBusca = true;
      const listTagsReq = await this.$http.post(`/v1/chatv2/relatorio-tags`, {
        de: this.de,
        ate: this.ate,
        centrocusto: this.centrocusto,
        operador: this.operador,
        busca: this.busca
      });

      this.tags = listTagsReq.data.tags;

      const listDetReq = await this.$http.post(`/v1/chatv2/relatorio-detalhado`, {
        de: this.de,
        ate: this.ate,
        centrocusto: this.centrocusto,
        ultimoAResponder: this.ultimoAResponder,
        dataMensagem: this.dataMensagem,
        operador: this.operador,
        desativo: this.desativo,
        respondido: this.respondido,
        limit: this.perPage,
        busca: this.busca
      });

      if (!listDetReq.data.success) return this.$vToastify.error(listDetReq.data.err);

      this.list = listDetReq.data.data;
      this.total = listDetReq.data.total;
      this.totalEnvio = listDetReq.data.total;

    },
    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;

      const listDetReq = await this.$http.post(`/v1/chatv2/relatorio-detalhado`, {
        de: this.de,
        ate: this.ate,
        centrocusto: this.centrocusto,
        ultimoAResponder: this.ultimoAResponder,
        dataMensagem: this.dataMensagem,
        operador: this.operador,
        desativo: this.desativo,
        respondido: this.respondido,
        busca: this.busca,
        limit: this.perPage,
        skip
      });
      this.list = listDetReq.data.data;
      this.total = listDetReq.data.total;
    },
    async downloadRelatorio() {
      if(!this.email) return this.$vToastify.error("Email é obrigatório para recebimento!");

      const req = await this.$http.post('/v1/chatv2/relatorio-detalhado/dowload', {
        email: this.email.trim(),
        de: this.de,
        ate: this.ate,
        operador: this.operador,
        centrocusto: this.centrocusto,
        ultimoAResponder: this.ultimoAResponder,
        dataMensagem: this.dataMensagem
      });
      if (req.data.success) {
        this.$vToastify.success(req.data.msg);
        this.email = "";
        this.progresso = 1;
        this.isLoading = true;
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
    async desativarTodasCentroCusto() {
      if (confirm("Desativar todas as conversas do centro de custo ?")) {
        const req = await this.$http.post('v1/chatV2/arquivarList', { allCentroCusto: true, centrocusto: this.form.centrocusto });
        if (req.data.success) {
          this.$vToastify.success(req.data.msg);
          this.numeros = [];
          this.form.numero = "";
          this.$modal.hide("arquivarMultiplas");
        } else {
          this.$vToastify.error(req.data.err);
        }
      }
    },
    async arquivarMultiplas() {
      if (!this.numeros.length) return this.$vToastify.error("Digite os números de contatos para arquivar");
      const req = await this.$http.post('v1/chatV2/arquivarList', { numeros: this.numeros, centrocusto: this.form.centrocusto });

      if (req.data.success) {
        this.$vToastify.success(req.data.msg);
        this.numeros = [];
        this.form.numero = "";
        this.$modal.hide("arquivarMultiplas");
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
    async addNumeros() {
      if (!this.form.numero) return this.$vToastify.error("Digite o número");
      this.numeros.push(this.form.numero);
      this.form.numero = "";
    },
    returnTempo(tempo) {
      if (tempo > 60) {
        const horas = tempo / 60;
        return `${parseInt(tempo / 60)} hora${horas > 1 ? 's' : ''}${tempo % 60 > 1 ? `, ${tempo % 60} minutos` : ''}`;
      } else {
        return `${tempo} minutos`
      }
    }
  },
  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    const operadoresReq = await this.$http.post("/v1/operador/list", {
      all: true,
      chatAcesso: true,
    });
    this.operadores = operadoresReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }

    await this.start();
  },
  async mounted() {
    this.sockets.subscribe(`progressao_conversas_buscar_${this.$store.state.user._id}`,(progresso) => {
      this.progressoBusca = progresso;
      this.isLoadingBusca = progresso > 0 && progresso !== 100;
    });

     this.sockets.subscribe(`progressao_conversas_${this.$store.state.user._id}`,(progresso) => {
      this.progresso = progresso;
      this.isLoading = progresso > 0 && progresso !== 100;
    });
  },
};
</script>
